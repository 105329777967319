<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <div v-if="data_loaded">
      <div class="text-end">
        <v-btn @click="new_form()" color="primary">اضافة</v-btn>
      </div>

      <BaseTable
        @delete_row="delete_row($event)"
        :headers="headers"
        ref="table"
        :delete_loader="delete_loader"
        :delete_meg="'حذف النموذج ؟ '"
        @close_delete="delete_dialog = false"
        :delete_dialog="delete_dialog"
        @show_delete="delete_dialog = true"
        :items="items"
        @edit_row="edit_row($event)"
      ></BaseTable>
    </div>
    <!--table -->

    <!-- <Form></Form> -->
  </v-container>
  <!--/ container -->
</template>

<script>
import BaseTable from "@/components/base/table";
// import Form from "@/components/forms/form";

export default {
  name: "forms",
  data() {
    return {
      data_loaded: false,
      title: "اضافة مصروف",
      delete_dialog: false,
      delete_loader: false,
      employee_index: -1,
      employee_data: null,
      attachments: [],
      dialog: false,
      files: [],
      form_obj: {
        type: {
          value: "",
          label: "نوع المصررف",
          type: "select",
          items: ["موظف", "شركة"],
        },
        employee: {
          value: "",
          label: "اختيار الموظف",
          type: "select",
        },
        payment_method: {
          value: "",
          label: "طريقة الدفع",
          type: "select",
          items: [],
        },
        amount: {
          value: "",
          label: "المبلغ",
          type: "number",
        },
        attachment: {
          value: null,
          label: "مرفق",
          type: "file",
        },
      },
      headers: [
        {
          value: "form_name",
          text: "اسم النموذج",
          align: "center",
        },
        {
          value: "questions",
          text: "عدد الأسئلة",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      items: [],
    };
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "forms",
            method: "get",
          },
        })
        .then((res) => {
          console.log(res);
          this.items = Object.assign([], res.data.data);
          this.$nextTick(() => {
            this.data_loaded = true;
          });
        });
    },
    delete_row(id) {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/${id}`,
            method: "delete",
          },
        })
        .then((res) => {
          console.log(res);
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_data();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    new_form() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "forms",
            method: "post",
          },
        })
        .then((res) => {
          this.$router.push(`form/${res.data.data}`);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    edit_row(item) {
      this.$router.push(`form/${item.item.id}`);
    },
  },
  created() {
    this.get_data();
  },
  components: {
    BaseTable,
    // Form,
  },
};
</script>
